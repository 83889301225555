import axios from 'axios';
import Config from '../config';
import {Toast} from 'vant';
import router from '../router/index';
import {getLangMessage, getDefaultLang} from '@/utils/lang';


const baseDomain = Config.domain + '/web'; // Change your api url here
const domain = Config.domain;
const kf = Config.kf;

export const customHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Lang: getDefaultLang(),
};


export const baseUrl = `${baseDomain}`;
export const domainUrl = `${domain}`;
export const kfUrl = `${kf}`;

const request = axios.create({
  baseURL: baseUrl,
  headers: customHeaders
});

//不需要loading加载
const requestloading = axios.create({
  baseURL: baseUrl,
  headers: customHeaders
});

let toast;

Toast.setDefaultOptions({duration: 3000});

// 请求拦截，设置token和语言
request.interceptors.request.use(config => {
  // 加载loading
  toast = Toast.loading({
    duration: 0, // 持续展示 toast
    message: getLangMessage('Loading'),
    forbidClick: true,
  });



  let token = localStorage.getItem("token");
  token && (config.headers['Authorization'] = token);

  config.headers['Lang'] = getDefaultLang();

  return config;
});

// 响应拦截器
request.interceptors.response.use(response => {
  toast && toast.clear();

  if (response.status !== 200) {
    return Promise.reject(response);
  }


  // 拦截错误码处理，如果错误则自动弹出错误提示。
  // if (response.data.code !== 0) {
  //   let lang = getDefaultLang();
  //   let code = convertLanguage(lang);
  //   // let error = response.data.info[code];
  //   let error = response.data.info;
  //   // vue.$toast.error(error, {
  //   //   position: "top-center",
  //   // });
  //   Toast.fail(response.data.info);
  //   return Promise.reject(response.data);
  // }
  // console.log(response);


  // 登陆失效跳转
  if (response.data.code == 999) {
    console.log(response.data.code)
    let lang = getDefaultLang();
    let code = convertLanguage(lang);
    // let error = response.data.info[code];
    // vue.$toast.error(error, {
    //   position: "top-center",
    // });
    router.push('/login')
    return Promise.reject(response.data);
  }

  return Promise.resolve(response.data);
}, error => {
  toast && toast.clear();
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误(400)';
        break;
      case 401:
        error.message = '未授权，请重新登录(401)';
        router.replace('/login');
        localStorage.removeItem('token');
        break;
      case 403:
        error.message = '拒绝访问(403)';
        break;
      case 404:
        error.message = '请求出错(404)';
        break;
      case 408:
        error.message = '请求超时(408)';
        break;
      case 500:
        error.message = '服务器错误(500)';
        break;
      case 501:
        error.message = '服务未实现(501)';
        break;
      case 502:
        error.message = '网络错误(502)';
        break;
      case 503:
        error.message = '服务不可用(503)';
        break;
      case 504:
        error.message = '网络超时(504)';
        break;
      default:
        error.message = `连接出错(${error.response.status})!`;
    }
  } else {
    if (JSON.stringify(error).includes('timeout')) {
      // Message.error('服务器响应超时，请刷新当前页')
    }
    error.message = '连接服务器失败'
  }

  return Promise.resolve(error.response);
})

export function convertLanguage(lang) {
  let code = 'en_us';
  switch (lang) {
    case 'zh-CN':
      code = 'zh_cn';
      break;
    default:
      break;
  }

  return code;
}

// 请求拦截，设置token和语言
requestloading.interceptors.request.use(config => {
  let token = localStorage.getItem("token");
  token && (config.headers['Authorization'] = token);
  config.headers['Lang'] = getDefaultLang();
  return config;
});

// 响应拦截器
requestloading.interceptors.response.use(response => {
  toast && toast.clear();

  if (response.status !== 200) {
    return Promise.reject(response);
  }


  // 拦截错误码处理，如果错误则自动弹出错误提示。
  // if (response.data.code !== 0) {
  //   let lang = getDefaultLang();
  //   let code = convertLanguage(lang);
  //   // let error = response.data.info[code];
  //   let error = response.data.info;
  //   // vue.$toast.error(error, {
  //   //   position: "top-center",
  //   // });
  //   Toast.fail(response.data.info);
  //   return Promise.reject(response.data);
  // }
  // console.log(response);


  // 登陆失效跳转
  if (response.data.code == 999) {
    console.log(response.data.code)
    let lang = getDefaultLang();
    let code = convertLanguage(lang);
    // let error = response.data.info[code];
    // vue.$toast.error(error, {
    //   position: "top-center",
    // });
    router.push('/login')
    return Promise.reject(response.data);
  }

  return Promise.resolve(response.data);
}, error => {
  toast && toast.clear();
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误(400)';
        break;
      case 401:
        error.message = '未授权，请重新登录(401)';
        router.replace('/login');
        localStorage.removeItem('token');
        break;
      case 403:
        error.message = '拒绝访问(403)';
        break;
      case 404:
        error.message = '请求出错(404)';
        break;
      case 408:
        error.message = '请求超时(408)';
        break;
      case 500:
        error.message = '服务器错误(500)';
        break;
      case 501:
        error.message = '服务未实现(501)';
        break;
      case 502:
        error.message = '网络错误(502)';
        break;
      case 503:
        error.message = '服务不可用(503)';
        break;
      case 504:
        error.message = '网络超时(504)';
        break;
      default:
        error.message = `连接出错(${error.response.status})!`;
    }
  } else {
    if (JSON.stringify(error).includes('timeout')) {
      // Message.error('服务器响应超时，请刷新当前页')
    }
    error.message = '连接服务器失败'
  }

  return Promise.resolve(error.response);
})

export default request;
export let reqloading = requestloading


export const serializeQuery = query => {
  return Object.keys(query)
      .map(
          key =>
              `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
      )
      .join('&');
};
