import Api, {baseUrl, serializeQuery, reqloading} from './index';


// 获取账户余额
export function Balance() {
  return Api.get(`/my/balance`)
}

// 获取用户邀请码
export function UserInvite() {
  return Api.get(`/my/info`)
}

// 获取用户邀请码
export function Userinfo() {
  return Api.get(`/my/index`)
}


// 获取用户邀请码
export function GetStart() {
  return Api.get(`/my/start`)
}

// 创建订单
export function SubmitOrder({cid}) {
  return reqloading.get('/rot_order/submit_order?cid=' + cid);
}

// 创建订单
export function ConfirmOrder({oid, add_id}) {
  return Api.post('/order/do_order', {oid, add_id, status: 1});
}


// 创建订单
export function ConfirmOrderLoading({oid, add_id}) {
  return reqloading.post('/order/do_order', {oid, add_id, status: 1});
}

// 订单历史
export function OrderHistory({status}) {
  return Api.get('/order/index?status=' + status);
}

// 充值历史
export function DepositHistory() {
  return Api.get('/ctrl/recharge_admin');
}

// 提现申请
export function WithdrawApply({num, paypassword, type}) {
  return Api.post('/ctrl/do_deposit', {num, paypassword, type})
}

// 提现历史
export function WithdrawHistory() {
  return Api.get('/ctrl/deposit_admin');
}

// 提现历史
export function WalletHistory() {
  return Api.get('/my/caiwu');
}

// 绑定银行卡
export function BindBank({address, bankname, network, paypassword, tel}) {
  return Api.post('/my/bind_bank', {address, bankname, network, paypassword, tel});
}

// 获取银行卡信息
export function Bankinfo() {
  return Api.get('/my/bankinfo');
}

// 获取最新消息
export function NewMsg() {
  return Api.get('/my/msg');
}

// 更新头像
export function UpdateAvatar({pic}) {
  return Api.post('/my/headimg', {pic});
}

// 更新密码
export function UpdatePassword({new_pwd, old_pwd, type}) {
  return Api.post('ctrl/set_pwd', {new_pwd, old_pwd, type})
}

// 更新支付密码
export function UpdatePayPassword({new_pwd, old_pwd, type}) {
  return Api.post('ctrl/set_ppwd', {new_pwd, old_pwd, type})
}

// 获取客服信息
export function GetCustomerServiceInfo() {
  return Api.get('/user/kefu')
}

// 获取内容
export function GetContentByKey({key}) {
  return Api.get('/index/content?key=' + key)
}

// 获取vip等级列表
export function GetVipList() {
  return Api.get('/index/level')
}

// // 上传头像
// export function UpdateAvatar({avatar}) {
//   return Api.post('/user/upload_avatar', {avatar});
// }

// 获取已签到天数
export function GetSigninDay() {
  return Api.get('/my/get_signin_day');
}

// 签到
export function Signin() {
  return Api.get('/my/do_signin');
}