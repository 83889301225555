<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
    <div class="serve-icon">
      <img :src="serve_icon" @click="to('/contact')" />
    </div>
  </div>
</template>

<script>


export default {
  name: "App",
  components: {
  },
  data() {
    return {
      serve_icon:require('./assets/img/plat/service.png'),
    }
  },
  methods: {
    to(path) {
      this.$router.push(path)
    },
  }
};
</script>

<style>
#app{
  height: 100%;
}
</style>
